<template>
  <v-row no-gutters justify="center">

    <v-col cols="12" sm="10" md="9">
      <flats_list_big/>
    </v-col>

  </v-row>
</template>

<script>

export default {
  components: {
    flats_list_big: () => import("@/site/modules/server/flats_list_big/index"),
  },
  data: () => ({
    // server_data: null,
  }),
}
</script>
